import { template as template_68c28e1b1c5c42ebb620dc3bbe42a7fc } from "@ember/template-compiler";
const FKControlMenuContainer = template_68c28e1b1c5c42ebb620dc3bbe42a7fc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
